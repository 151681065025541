<template>
    <article class="ewf_promo">
        <div class="ewf_promo__waves_holder"></div>
        <div class="ewf_promo__body">
            <article class="slide slide--light slide--spacing-around" ref="slide_thanks">
                <div class="slide__section slide__section--first-down-indicator">
                    <h3 class="slide__title slider__title--space_bottom" :style="oneGlassTextStyle">
                        {{
                            $t("review_form__success_page__you_contributed_one_glass", {
                                clean_drinking_water: $l(
                                    "review_form__success_page__clean_drinking_water",
                                    "clean drinking water"
                                ),
                            })
                        }}
                    </h3>
                    <Pointer
                        :style="{
                            opacity: pointerStyle.opacity,
                        }"
                        :height="pointerStyle.height"
                    />
                </div>
            </article>
            <div class="waves_holder">
                <div
                    class="waves"
                    :class="{
                        'waves--locked': waterSlideLocation < 0,
                    }"
                >
                    <Wave :height="80" class="wave wave--light" color="#A1C5FB" />
                    <Wave :height="72" class="wave wave--dark" :heightVariation="20" />
                </div>
            </div>
            <article class="slide slide--water" ref="slide_water">
                <div class="slide__body">
                    <h3 class="slide__title slide__title--white slide__title--big">
                        {{
                            $t(
                                "review_form__success_page__bottom_slide__title",
                                "Help mee met onze missie"
                            )
                        }}
                    </h3>
                    <div class="slide__logos">
                        <EwfLogo :height="31" />
                        <span class="slide__logos_plus">+</span>
                        <FeedbackCompanyLogo color="white" :height="31" />
                    </div>
                    <div
                        class="slide__paragraph"
                        v-html="
                            $t(
                                'review_form__success_page__bottom_slide__text',
                                `
                                Naast het delen van een ervaring is er nog zoveel meer te delen.
                                Voor elke geschreven review doneren wij aan de Elemental Water
                                Foundation.
                                Een duurzaam project dat schoon drinkwater toegankelijk maakt
                                voor mensen in ontwikkelingslanden.
                                Deel jij met ons mee?
                            `
                            )
                        "
                    ></div>

                    <div class="slide__buttons">
                        <a
                            v-if="showDonateButton"
                            target="_blank"
                            title="Donate to Elemental Water Foundation"
                            href="https://elementalwaterfoundation.com/clean-water-for-madagascar/"
                            class="button button--white"
                        >
                            {{ $t("review_form__success_page__bottom_slide__donate", "Donate!") }}
                        </a>
                        <a
                            target="_blank"
                            title="More info about Elemental Water Foundation"
                            href="https://elementalwaterfoundation.com/"
                            class="button button--transparant"
                        >
                            {{
                                $t(
                                    "review_form__success_page__bottom_slide__more_info",
                                    "Meer informatie"
                                )
                            }}
                        </a>
                    </div>
                </div>
            </article>
            <div class="waves waves--bottom">
                <Wave class="wave" color="rgba(255, 255, 255, 0.2)" direction="right" />
            </div>
            <div class="bottom-gradient"></div>
        </div>
    </article>
</template>

<script>
import { FeedbackCompanyLogo } from '@feedbackcompany/feedback-company-vue-components';
import SweetScroll from 'sweet-scroll';
import EwfLogo from '@/components/atoms/EwfLogo.vue';
import Pointer from '@/components/atoms/Pointer.vue';
import Wave from '@/components/atoms/Wave.vue';

export default {
    name: 'EwfPromo',
    components: {
        EwfLogo,
        Pointer,
        FeedbackCompanyLogo,
        Wave,
    },
    data() {
        return {
            hasBeenDestroyed: false,
            waterSlideLocation: undefined,
            thanksSlidePercentage: undefined,
            scrollPoint: undefined,
            cancelScroll: false,
            scroller: new SweetScroll({
                trigger: '[data-start-scroller]',
                cancellable: true,
                easing: 'easeInOutQuint',
                offset: 0,
                cancel: () => {
                    this.cancelScroll = true;
                },
            }),
        };
    },
    computed: {
        oneGlassTextStyle() {
            const startingPoint = 65;
            let percentage = this.thanksSlidePercentage - startingPoint / 100;
            percentage = (percentage * 100) / (100 - startingPoint);
            if (percentage > 1) percentage = 1;
            if (percentage < 0) percentage = 0;
            return {
                opacity: percentage,
            };
        },
        pointerStyle() {
            const startingPoint = 80;
            let percentage = this.thanksSlidePercentage - startingPoint / 100;
            percentage = (percentage * 100) / (100 - startingPoint);
            if (percentage > 1) percentage = 1;
            if (percentage < 0) percentage = 0;
            return {
                opacity: percentage || 0,
                height: percentage * 64 || 0,
            };
        },
        showDonateButton() {
            const excludedStoreNames = [
                'Profielenmagazijn',
                'Rubbermagazijn',
                'Rubbermatten2000',
                'Siliconenmagazijn',
                'Slangenmagazijn',
                'Speelplaatstegels.nl',
            ];
            return excludedStoreNames.includes(this.$store.state.shop.name) === false;
        },
    },
    mounted() {
        this.hasBeenDestroyed = false;
        // Watch Scroll Location
        const frame = () => {
            this.waterSlideLocation = this.$refs.slide_water.getBoundingClientRect().top - window.innerHeight;
            this.thanksSlidePercentage = -(this.$refs.slide_thanks.getBoundingClientRect().top - window.innerHeight)
                / window.innerHeight;

            window.requestAnimationFrame(() => {
                if (this.hasBeenDestroyed === false) {
                    frame();
                }
            });
        };
        frame();
    },
    destroyed() {
        this.hasBeenDestroyed = true;
    },
    methods: {
        introScroll() {
            const stoppingPoints = [
                {
                    to: window.scrollY + this.$refs.slide_thanks.getBoundingClientRect().top,
                    duration: 2000,
                    delay: 0,
                },
                {
                    to: window.scrollY + this.$refs.slide_water.getBoundingClientRect().top - 100,
                    duration: 3000,
                    delay: 3000,
                },
            ];
            stoppingPoints.forEach((stoppingPoint, index) => {
                window.setTimeout(() => {
                    if (!this.cancelScroll) {
                        this.scroller.update({
                            duration: stoppingPoint.duration,
                        });
                        this.scroller.to({
                            top: stoppingPoint.to,
                        });
                    } else if (index === stoppingPoints.length - 1) {
                        this.cancelScroll = false;
                    }
                }, stoppingPoint.delay);
            });
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/style_variables/style_variables.scss";

.ewf_promo {
    width: 100%;

    .ewf_promo__body {
        width: 100%;
        height: 90%;
        position: relative;
    }
}
.waves_holder {
    height: 116px;
    width: 100%;
    background-color: $grey_bg;
}
.waves {
    width: 100%;
    height: 116px;
    position: fixed;
    overflow: hidden;
    z-index: 3;
    bottom: 0px;
    transform: translateY(100%);
    animation: wavesOnLoad 650ms ease-out 1333ms forwards;

    &.waves--locked {
        position: relative;
    }

    &.waves--bottom {
        margin-top: -1px;
        position: relative;
        .wave {
            z-index: 2;
            opacity: 0.7;
        }
    }
}
@keyframes wavesOnLoad {
    from {
        transform: translateY(100%);
    }
    to {
        transform: translateY(0%);
    }
}
.wave {
    width: 100%;
    position: absolute;
    top: 0px;
    &.wave--dark {
        top: 10px;
    }
}
.bottom-gradient {
    position: absolute;
    bottom: 0px;
    width: 100%;
    pointer-events: none;
    height: 600px;
    margin-top: -400px;
    background-image: linear-gradient(0deg, #62c6c4 0%, #438bf8 59%, #438bf8 95%);
}
.slide {
    width: 100vw;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0px 16px;
}
.slide--light {
    background-color: $grey_bg;
}
.slide--water {
    background-color: $blue;
    min-height: 78vh;
}
.slide--spacing-around {
    justify-content: space-around;
}
.slide__section--first-down-indicator {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.slide__title {
    @extend %headline4_style;
    text-align: center;
}
.slide__title--big {
    @extend %headline3_style;
    font-size: 48px;
    margin: 44px 0px;
    text-align: center;
}
.slider__title--space_bottom {
    margin-bottom: 24px;
}
.slide__title--blue {
    color: $blue;
}
.slide__title--white {
    color: $white;
}
.slide__body {
    position: relative;
    z-index: 2;
}
.slide__logos {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 36px 0px;
}
.slide__logos_plus {
    font-size: 24px;
    margin: 0px 20px;
    color: $white;
    font-weight: 800;
}
.slide__paragraph {
    @extend %body1_style;
    text-align: center;
    color: $white;
    max-width: 650px;
    margin: 24px auto 0 auto;
}
.slide__buttons {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 24px;
    .button {
        max-width: 304px;
        width: 100%;
        margin-top: 12px;
    }
}
.button {
    @extend %body1_style;
    text-decoration: none;
    font-weight: 700;
    display: inline-flex;
    justify-content: center;
    font-size: 14px;
    padding: 14px;
    min-width: 144px;
    border: 1px solid $white;
    border-radius: 30px;
    outline: 0;
    transition: all 0.125s;
    &:hover {
        cursor: pointer;
        transform: scale(1.05, 1.05);
    }
}
.button--white {
    background-color: $white;
}
.button--transparant {
    background-color: transparent;
    color: $white;
}

#bottom-pointer {
    margin-top: 56px;
}
</style>
