var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wave",class:{
        'wave--direction-right': _vm.direction === 'right',
    },style:(_vm.cssProps)},[_c('div',{staticClass:"wave__rail",style:({
            height: _vm.dimensions.svgHeight + 74 + 'px',
            width: _vm.amountOfWaves * 1600 + 'px',
        })},_vm._l((_vm.amountOfWaves),function(index){return _c('svg',{key:index,staticClass:"wave__svg",style:({
                marginLeft: -index - 2 + 'px',
            }),attrs:{"width":"1600","height":_vm.dimensions.svgHeight + _vm.heightVariation,"xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"fill":_vm.color,"fill-rule":"evenodd","d":("" + (_vm.dimensions.wavePath))}})])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }