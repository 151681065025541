<template>
    <div
        class="wave"
        :style="cssProps"
        :class="{
            'wave--direction-right': direction === 'right',
        }"
    >
        <div
            class="wave__rail"
            :style="{
                height: dimensions.svgHeight + 74 + 'px',
                width: amountOfWaves * 1600 + 'px',
            }">
            <svg
                v-for="index in amountOfWaves"
                :key="index"
                :style="{
                    marginLeft: -index - 2 + 'px',
                }"
                class="wave__svg"
                width="1600"
                :height="dimensions.svgHeight + heightVariation"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    :fill="color"
                    fill-rule="evenodd"
                    :d="`${dimensions.wavePath}`"
                />
            </svg>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Wave',
    data() {
        return {
            screenWidth: window.innerWidth,
        };
    },
    props: {
        color: {
            type: String,
            default: '#438BF8',
        },
        height: {
            type: Number,
            default: 100,
        },
        // Amount wave moves up and down, handy for a wavy effect
        heightVariation: {
            type: Number,
            default: 0,
        },
        direction: {
            type: String,
            validator(value) {
                return ['left', 'right'].indexOf(value) !== -1;
            },
            default: 'left',
        },
    },
    computed: {
        amountOfWaves() {
            return Math.ceil(window.innerWidth / 1600) + 1;
        },
        dimensions() {
            const dimensions = {};
            dimensions.wavePath = `
                M.005 ${this.height}
                C311 ${this.height} 409.898-.25 811 0
                c400 0 500 ${this.height} 789 ${this.height}
                v77
                H0s.005-48 .005-77
                z
            `;
            dimensions.svgHeight = this.height + 74;
            return dimensions;
        },
        cssProps() {
            return {
                height: `${this.dimensions.svgHeight + 74}px`,
                '--height-variation': this.heightVariation,
            };
        },
    },
};
</script>

<style lang="scss" scoped>
.wave {
    margin: 0px;
    padding: 0px;
    height: 198px;
    position: relative;
    overflow: hidden;
}
.wave--direction-right {
    transform: scaleX(-1);
}
.wave__rail {
    width: 3200px;
    height: 198px;
    bottom: -1px;
    position: absolute;
    animation: swell 9500ms cubic-bezier( 0.36, 0.45, 0.63, 0.53) infinite;
}
.wave__svg {
    height: 100%;
    animation: wave 10000ms cubic-bezier( 0.36, 0.45, 0.63, 0.53) -1.25s infinite;
    transform: translate3d(0, 0, 0);
}

@keyframes wave {
    0% {
        margin-left: 0;
    }
    100% {
        transform: translateX(-1596px);
    }
}
@keyframes swell {
    0%, 100% {
        transform: translate3d(0, calc(var(--height-variation) * 1px), 0);
    }
    50% {
        transform: translate3d(0,0px,0);
    }
}

</style>
