<template>
<!-- eslint-disable max-len -->
<svg
    :width="widthPixels"
    :height="heightPixels"
    viewBox="0 0 1260 400"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
>   <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Artboard" transform="translate(-30.000000, -797.000000)" fill="#FFFFFF" fill-rule="nonzero">
            <g id="170925-logo-foundation" transform="translate(30.000000, 797.000000)">
                <path d="M554.8,398.8 C540.5,393.4 531.2,382.1 526.3,364.1 C523.9,354.9 523.8,330.2 526.3,321.1 C530.4,306.1 539.8,293.7 551.1,288.7 C562.2,283.7 577.2,284.4 586.7,290.4 C595.4,295.9 603.1,307.3 606.5,319.7 C609,328.8 609.6,349.8 607.6,360.1 C604.5,376.6 594.7,391.6 583.4,397.2 C579.4,399.1 576.6,399.6 568.5,399.8 C561.9,400 557.2,399.6 554.8,398.8 Z M573.5,364.5 C576.4,358.7 576.5,358.3 576.5,343.1 C576.5,330.6 576.1,326.6 574.7,322.3 C570,308.9 560,313 557.1,329.6 C554,347.2 557.8,368.6 564.3,371 C568,372.4 570.4,370.7 573.5,364.5 Z" id="Shape"></path>
                <path d="M645.3,398.7 C635,395.4 628.7,389.8 624.1,380.1 L621.5,374.6 L621.2,330.9 L620.9,287.1 L636.5,287.1 L652,287.1 L652,326 C652,349.1 652.4,366 653,367.6 C655.1,373.2 662.9,372.8 664.9,367 C665.6,364.9 666,351.2 666,325.5 L666,287.1 L681.6,287.1 L697.1,287.1 L696.8,331.4 C696.5,375.4 696.5,375.6 694.1,380.8 C691.3,387 683.8,394.4 677.2,397.4 C671.3,400.1 652,400.9 645.3,398.7 Z" id="Path"></path>
                <path d="M1113.2,398 C1101.7,393.5 1091.5,380.6 1087.4,365.6 C1085.2,357.3 1084.4,337 1086,327.3 C1090.4,300.1 1108.3,283.5 1131.2,285.4 C1149.1,286.9 1162.3,300 1167.7,321.7 C1170.3,332.5 1170.1,355.3 1167.2,365.4 C1164.2,375.9 1161.1,381.7 1154.9,388.5 C1147,397.2 1141.8,399.4 1129,399.8 C1120.2,400.1 1117.6,399.8 1113.2,398 Z M1134,365.5 C1139,355.4 1139,328.9 1134.1,319.3 C1130.1,311.5 1123.3,313.1 1119.6,322.7 C1117.2,329 1116.3,347.8 1117.9,357.1 C1120.6,372 1128.8,376.3 1134,365.5 Z" id="Shape"></path>
                <path d="M0,199.6 L0,0.1 L199.5,0.1 L399,0.1 L399,199.6 L399,399.1 L199.5,399.1 L0,399.1 L0,199.6 Z M213.3,291.5 C247.1,284.1 271.5,248.9 264.5,217.4 C260,197 242.6,163.9 215.1,123.4 C208.4,113.6 202.2,104.5 201.1,103.2 L199.3,100.8 L189.3,115.2 C162.9,153.2 144.5,185.8 136.8,208.3 C131,225.2 132.7,242.6 141.7,259.1 C147,268.7 159.6,280.8 169.3,285.6 C183.6,292.7 198.8,294.7 213.3,291.5 Z" id="Shape"></path>
                <polygon id="Path" points="463 343.1 463 287.1 489.5 287.1 516 287.1 516 300.6 516 314.1 505 314.1 494 314.1 494 321.6 494 329.1 504.5 329.1 515 329.1 515 342.1 515 355.1 504.5 355.1 494 355.1 494 377.1 494 399.1 478.5 399.1 463 399.1"></polygon>
                <path d="M711,343.1 L711,287.1 L725.8,287.1 L740.7,287.1 L748.8,308.4 C753.3,320 758.3,333.4 760,338.1 C762.1,344.2 762.8,345.5 762.5,342.6 C761.5,335 760,308.6 760,297.9 L760,287.1 L775,287.1 L790,287.1 L790,343.1 L790,399.1 L775.3,399.1 L760.5,399.1 L754.2,381.8 C750.8,372.4 746.2,359.9 744,354.1 L740.2,343.6 L740.7,371.4 L741.3,399.1 L726.2,399.1 L711,399.1 L711,343.1 Z" id="Path"></path>
                <path d="M804,343.1 L804,287.1 L820.8,287.1 C846.6,287.1 855.9,289.9 866.5,300.6 C877.3,311.4 881.5,323.4 881.4,343.6 C881.4,364.5 876.8,377.4 866,387.4 C856,396.7 846.6,399 819.3,399.1 L804,399.1 L804,343.1 Z M842,368.2 C843.4,367.3 845.6,364.3 847,361.5 C849.3,357 849.5,355.5 849.5,343.6 C849.5,332.4 849.2,330 847.3,325.9 C844.7,320.1 840.6,316.1 837.4,316.1 L835,316.1 L835,343.7 L835,371.2 L837.3,370.6 C838.5,370.2 840.6,369.2 842,368.2 Z" id="Shape"></path>
                <path d="M884,398.4 C884,397.9 889.9,372.7 897.2,342.4 L910.4,287.1 L929.2,287.1 C939.5,287.1 948,287.3 948,287.5 C948,287.7 953.2,312.2 959.5,342.1 C965.8,371.9 971,396.9 971,397.7 C971,398.8 968.1,399.1 955.1,399.1 L939.2,399.1 L938.4,392.4 L937.5,385.6 L927.9,385.3 C917,385 917.9,384.3 916.5,394.4 L915.8,399.1 L899.9,399.1 C891.1,399.1 884,398.8 884,398.4 Z M932.9,355.4 C932.4,352.7 931.2,343.6 930.1,335.1 C929.1,326.6 928.2,321.2 928.1,323.1 C928,325 926.7,334.1 925.1,343.4 L922.4,360.1 L928,360.1 L933.7,360.1 L932.9,355.4 Z" id="Shape"></path>
                <polygon id="Path" points="988 357.1 988 315.1 979.5 315.1 971 315.1 971 301.1 971 287.1 1003.5 287.1 1036 287.1 1036 301.1 1036 315.1 1027.5 315.1 1019 315.1 1019 357.1 1019 399.1 1003.5 399.1 988 399.1"></polygon>
                <polygon id="Path" points="1042 343.1 1042 287.1 1057.5 287.1 1073 287.1 1073 343.1 1073 399.1 1057.5 399.1 1042 399.1"></polygon>
                <path d="M1182,343.1 L1182,287 L1196.6,287.3 L1211.3,287.6 L1217.3,303.1 C1220.5,311.6 1225.4,324.7 1228.1,332.1 C1231.1,340.3 1232.8,344 1232.5,341.6 C1232.3,339.4 1231.7,326.2 1231.4,312.4 L1230.7,287.1 L1245.4,287.1 L1260,287.1 L1260,343.1 L1260,399.2 L1245.4,398.9 L1230.8,398.6 L1225.8,385.1 C1223,377.7 1218.4,365 1215.6,356.8 L1210.4,342.1 L1210.8,370.6 L1211.3,399.1 L1196.6,399.1 L1182,399.1 L1182,343.1 Z" id="Path"></path>
                <path d="M479.5,252.9 C479.1,251.7 456.7,151.9 455.3,144.8 L454.7,142 L470.1,142.3 L485.5,142.6 L488.2,159.6 C489.7,169 491.8,182.9 492.8,190.6 C494.7,204.6 494.7,204.6 495.4,198.6 C496.4,190.3 504.1,143.8 504.7,142.9 C504.9,142.4 510.9,142.1 518,142.1 C529.4,142.1 530.9,142.3 531.4,143.9 C532.6,147.7 539.1,185.2 540.2,194.3 C540.8,199.7 541.5,203.9 541.7,203.7 C541.9,203.6 542.7,197.7 543.5,190.8 C544.9,178.9 549.4,149.3 550.5,144.4 C551,142.1 551.3,142.1 566,142.1 C574.3,142.1 581,142.4 580.901105,142.9 C580.901105,143.3 575.9,168.4 569.7,198.6 L558.5,253.6 L543.5,253.6 L528.6,253.6 L524.3,229.6 C522,216.4 519.8,202.8 519.4,199.4 C519,196 518.4,193.4 518,193.6 C517.6,193.8 517,196.9 516.8,200.3 C516.5,203.8 514.8,217.3 513,230.4 L509.7,254.1 L494.8,254.1 C483.8,254.1 479.8,253.8 479.5,252.9 Z" id="Path"></path>
                <path d="M581,252.8 C581,252.1 586.2,229.9 592.5,203.5 C598.9,177.2 604.8,152.6 605.6,148.9 L607.2,142.1 L626.1,142.1 C636.5,142.1 645,142.4 645,142.9 C645,143.3 650.2,168.1 656.5,197.9 C662.8,227.8 668,252.6 668,253.2 C668,253.7 661.5,254.1 652.1,254.1 L636.2,254.1 L635.5,248.9 C634.3,239.6 635,240.1 624.5,240.1 C614.1,240.1 614.6,239.7 613.5,248.4 L612.8,254.1 L596.9,254.1 C584.4,254.1 581,253.8 581,252.8 Z M630,213.9 C630,213.2 629.1,205.9 628,197.7 C626.9,189.6 625.9,181 625.9,178.7 C625.8,176.2 625.6,175.5 625.3,177.1 C625.1,178.5 623.8,186.8 622.4,195.6 C621,204.4 619.6,212.4 619.4,213.4 C618.9,214.9 619.6,215.1 624.4,215.1 C628.2,215.1 630,214.7 630,213.9 Z" id="Shape"></path>
                <polygon id="Path" points="685 212.1 685 170.1 676.5 170.1 668 170.1 668 156.1 668 142.1 700 142.1 732 142.1 732 156.1 732 170.1 723.5 170.1 715 170.1 715 212.1 715 254.1 700 254.1 685 254.1"></polygon>
                <polygon id="Path" points="739 198.1 739 142.1 767.5 142.1 796 142.1 796 155.6 796 169.1 783 169.1 770 169.1 770 176.6 770 184.1 781.5 184.1 793 184.1 793 197.1 793 210.1 781.5 210.1 770 210.1 770 218.1 770 226.1 783 226.1 796 226.1 796 240.1 796 254.1 767.5 254.1 739 254.1"></polygon>
                <path d="M807,198.1 L807,142.1 L825.8,142.1 C855.2,142.2 866,145.2 874.1,155.3 C879.5,162.2 881.5,168.8 881.5,179.6 C881.4,192.6 878.2,199.4 869.3,205.1 L865.2,207.7 L876.1,230.4 C882.1,242.9 887,253.3 887,253.6 C887,253.9 879.5,254 870.3,253.9 L853.6,253.6 L845.8,233.1 L838.1,212.6 L838,233.4 L838,254.1 L822.5,254.1 L807,254.1 L807,198.1 Z M845.8,191.5 C849,189.3 851,184.8 851,179.7 C851,173.8 847.3,169.3 841.8,168.4 L838,167.8 L838,180.5 L838,193.1 L840.8,193.1 C842.3,193.1 844.6,192.4 845.8,191.5 Z" id="Shape"></path>
                <polygon id="Path" points="463 55.6 463 0.1 491 0.1 519 0.1 519 13.6 519 27.1 506 27.1 493 27.1 493 34.6 493 42.1 504.5 42.1 516 42.1 516 55.1 516 68.1 504.5 68.1 493 68.1 493 75.6 493 83.1 506 83.1 519 83.1 519 97.1 519 111.1 491 111.1 463 111.1"></polygon>
                <polygon id="Path" points="530 55.6 530 0.1 545.5 0.1 561 0.1 561 41.6 561 83.1 572 83.1 583 83.1 583 97.1 583 111.1 556.5 111.1 530 111.1"></polygon>
                <polygon id="Path" points="591 55.6 591 0.1 619 0.1 647 0.1 647 13.6 647 27.1 634.5 27.1 622 27.1 622 34.6 622 42.1 633.5 42.1 645 42.1 645 55.1 645 68.1 633.5 68.1 622 68.1 622 75.6 622 83.1 634.5 83.1 647 83.1 647 97.1 647 111.1 619 111.1 591 111.1"></polygon>
                <path d="M656.4,107.4 C656.7,105.3 659.5,82 662.5,55.6 C665.5,29.2 668.3,5.9 668.6,3.9 L669.2,0.1 L688,0.1 L706.8,0.1 L707.9,6.3 C708.5,9.8 710.1,19.8 711.6,28.6 C713,37.4 714.5,46 715,47.6 L715.8,50.6 L715.9,47.6 C716,46 718,34.7 720.3,22.6 L724.5,0.6 L743.2,0.3 L762,0.1 L762.4,2.3 C762.7,3.6 765,26.4 767.5,53.1 C770,79.8 772.3,103.7 772.7,106.4 L773.3,111.1 L757.3,111.1 L741.3,111.1 L740.7,102.9 C740.3,98.3 739.8,85.8 739.4,75.1 C739,64.4 738.4,54.9 737.9,54.1 C737.3,53 737.1,53.2 737,55.1 C737,56.5 734.7,69.3 732,83.6 C729.3,97.9 727,110 727,110.4 C727,110.8 721.3,111 714.3,110.9 L701.5,110.6 L697.3,84.6 C695,70.3 693,57.3 693,55.7 C692.9,41.4 689.2,88 689,106.9 L689,111.1 L672.4,111.1 L655.8,111.1 L656.4,107.4 Z" id="Path"></path>
                <polygon id="Path" points="785 55.6 785 0.1 813 0.1 841 0.1 841 13.6 841 27.1 828.5 27.1 816 27.1 816 34.6 816 42.1 827.5 42.1 839 42.1 839 55.1 839 68.1 827.5 68.1 816 68.1 816 75.6 816 83.1 828.5 83.1 841 83.1 841 97.1 841 111.1 813 111.1 785 111.1"></polygon>
                <path d="M853,55.6 L853,0 L867.7,0.3 L882.4,0.6 L888.3,15.6 C891.5,23.9 896.3,36.7 899.1,44.1 C902.1,52.4 903.9,56 903.5,53.6 C903.2,51.4 902.7,38.5 902.4,24.9 L901.7,0.1 L916.4,0.1 L931,0.1 L931,55.6 L931,111.2 L916.4,110.9 L901.8,110.6 L894.8,91.6 C890.9,81.2 886.4,68.5 884.6,63.5 L881.5,54.5 L881.9,82.8 L882.2,111.1 L867.6,111.1 L853,111.1 L853,55.6 Z" id="Path"></path>
                <polygon id="Path" points="954 69.1 954 27.1 946 27.1 938 27.1 938 13.6 938 0.1 970 0.1 1002 0.1 1002 13.6 1002 27.1 993.5 27.1 985 27.1 985 69.1 985 111.1 969.5 111.1 954 111.1"></polygon>
                <path d="M999.2,109.4 C999.5,108.4 1005.5,83.4 1012.6,53.9 L1025.5,0.1 L1044.1,0.1 L1062.8,0.1 L1063.4,2.8 C1063.7,4.4 1068.9,29 1075,57.5 C1081,86.1 1086,109.9 1086,110.3 C1086,110.7 1078.9,111.1 1070.1,111.1 L1054.2,111.1 L1053.5,105.4 C1052.4,96.7 1052.9,97.1 1042.5,97.1 L1033.2,97.1 L1031.9,104.1 L1030.7,111.1 L1014.7,111.1 C1000,111.1 998.8,111 999.2,109.4 Z M1047.5,66.4 C1047.1,63.2 1046,54.1 1045,46.1 C1044,38.1 1043.1,33.4 1043.1,35.6 C1043,37.8 1041.7,46.9 1040.2,55.9 L1037.5,72.1 L1042.9,72.1 L1048.3,72.1 L1047.5,66.4 Z" id="Shape"></path>
                <polygon id="Path" points="1094 55.6 1094 0.1 1109 0.1 1124 0.1 1124 41.6 1124 83.1 1135.5 83.1 1147 83.1 1147 97.1 1147 111.1 1120.5 111.1 1094 111.1"></polygon>
            </g>
        </g>
    </g>
</svg>
</template>

<script>
// eslint-disable-line max-len

/**
 * @group Branding
 * EWF Logo.
 */
export default {
    name: 'EwfLogo',
    props: {
        width: {
            type: Number,
        },
        height: {
            type: Number,
            default: 48,
        },
    },
    computed: {
        dimensions() {
            const aspectRatio = 251 / 71;
            let { width, height } = this;
            if (!height) {
                height = width / aspectRatio;
            }
            if (!width) {
                width = height * aspectRatio;
            }
            return {
                width,
                height,
            };
        },
        widthPixels() {
            return `${this.dimensions.width}px`;
        },
        heightPixels() {
            return `${this.dimensions.height}px`;
        },
    },
};
</script>
