<template>
    <svg
        width="14px"
        :height="height + 14"
        :viewBox="`0 0 14 ${height + 14}`"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
    >
        <defs>
            <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" :id="`${gradientId}_Pointer`">
                <stop
                    v-for="colorStop in colorStops"
                    :key="colorStop.position"
                    :stop-color="colorStop.color"
                    :offset="colorStop.position"
                ></stop>
            </linearGradient>
        </defs>
        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Final-Mobile" transform="translate(-123.000000, -729.000000)">
                <g id="Pointer" transform="translate(123.000000, 729.000000)">
                    <rect
                        id="Rectangle"
                        :stroke="colorStops[colorStops.length - 1].color"
                        stroke-width="2"
                        x="1"
                        :y="height"
                        width="12"
                        height="12"
                        rx="6"
                    />
                    <rect
                        id="Rectangle"
                        :fill="`url(#${gradientId}_Pointer)`"
                        x="6"
                        y="0"
                        width="2"
                        :height="height"
                    />
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
export default {
    name: 'Pointer',
    data() {
        return {
            gradientId: null,
        };
    },
    props: {
        height: {
            type: Number,
            required: true,
        },
        colorStops: {
            type: Array,
            default: () => [
                { color: '#57B1EF', position: '0%' },
                { color: '#6494FD', position: '100%' },
            ],
        },
    },
    mounted() {
        this.gradientId = this._uid;
    },
};
</script>

<style lang="scss" scoped>
</style>
