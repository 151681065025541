var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('article',{staticClass:"ewf_promo"},[_c('div',{staticClass:"ewf_promo__waves_holder"}),_c('div',{staticClass:"ewf_promo__body"},[_c('article',{ref:"slide_thanks",staticClass:"slide slide--light slide--spacing-around"},[_c('div',{staticClass:"slide__section slide__section--first-down-indicator"},[_c('h3',{staticClass:"slide__title slider__title--space_bottom",style:(_vm.oneGlassTextStyle)},[_vm._v(" "+_vm._s(_vm.$t("review_form__success_page__you_contributed_one_glass", { clean_drinking_water: _vm.$l( "review_form__success_page__clean_drinking_water", "clean drinking water" ), }))+" ")]),_c('Pointer',{style:({
                        opacity: _vm.pointerStyle.opacity,
                    }),attrs:{"height":_vm.pointerStyle.height}})],1)]),_c('div',{staticClass:"waves_holder"},[_c('div',{staticClass:"waves",class:{
                    'waves--locked': _vm.waterSlideLocation < 0,
                }},[_c('Wave',{staticClass:"wave wave--light",attrs:{"height":80,"color":"#A1C5FB"}}),_c('Wave',{staticClass:"wave wave--dark",attrs:{"height":72,"heightVariation":20}})],1)]),_c('article',{ref:"slide_water",staticClass:"slide slide--water"},[_c('div',{staticClass:"slide__body"},[_c('h3',{staticClass:"slide__title slide__title--white slide__title--big"},[_vm._v(" "+_vm._s(_vm.$t( "review_form__success_page__bottom_slide__title", "Help mee met onze missie" ))+" ")]),_c('div',{staticClass:"slide__logos"},[_c('EwfLogo',{attrs:{"height":31}}),_c('span',{staticClass:"slide__logos_plus"},[_vm._v("+")]),_c('FeedbackCompanyLogo',{attrs:{"color":"white","height":31}})],1),_c('div',{staticClass:"slide__paragraph",domProps:{"innerHTML":_vm._s(
                        _vm.$t(
                            'review_form__success_page__bottom_slide__text',
                            "\n                            Naast het delen van een ervaring is er nog zoveel meer te delen.\n                            Voor elke geschreven review doneren wij aan de Elemental Water\n                            Foundation.\n                            Een duurzaam project dat schoon drinkwater toegankelijk maakt\n                            voor mensen in ontwikkelingslanden.\n                            Deel jij met ons mee?\n                        "
                        )
                    )}}),_c('div',{staticClass:"slide__buttons"},[(_vm.showDonateButton)?_c('a',{staticClass:"button button--white",attrs:{"target":"_blank","title":"Donate to Elemental Water Foundation","href":"https://elementalwaterfoundation.com/clean-water-for-madagascar/"}},[_vm._v(" "+_vm._s(_vm.$t("review_form__success_page__bottom_slide__donate", "Donate!"))+" ")]):_vm._e(),_c('a',{staticClass:"button button--transparant",attrs:{"target":"_blank","title":"More info about Elemental Water Foundation","href":"https://elementalwaterfoundation.com/"}},[_vm._v(" "+_vm._s(_vm.$t( "review_form__success_page__bottom_slide__more_info", "Meer informatie" ))+" ")])])])]),_c('div',{staticClass:"waves waves--bottom"},[_c('Wave',{staticClass:"wave",attrs:{"color":"rgba(255, 255, 255, 0.2)","direction":"right"}})],1),_c('div',{staticClass:"bottom-gradient"})])])}
var staticRenderFns = []

export { render, staticRenderFns }